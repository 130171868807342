import MsgTrans from '@components/common/i18n/message-trans'
import { FastlyImage } from '@components/common/image'
import { PARTNERS_IMAGE_PATH } from '@lib/constants/geo-lp'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { t } from '@lingui/macro'

type Props = {
  backedBySectionHeading: string | LinguiJSMessageDescriptor
}

const AccrediatedOutsideIndia = ({ backedBySectionHeading }: Props) => {
  return (
    <>
      <h3 className="mt-5 mb-4 text-xl font-700 text-grey-800">
        <MsgTrans msg={backedBySectionHeading} />
      </h3>
      <div className="flex items-center justify-center space-x-2">
        <div className="flex flex-col items-center space-y-1 text-grey">
          <FastlyImage
            width={130}
            height={28}
            src={`${PARTNERS_IMAGE_PATH}/ycombinator.png`}
            alt={t`Y Combinator logo`}
          />
        </div>
        <div className="flex flex-col items-center space-y-1 text-grey">
          <FastlyImage
            width={120}
            height={28}
            src={`${PARTNERS_IMAGE_PATH}/rebright.png`}
            alt={t`Rebright logo`}
          />
        </div>
      </div>
    </>
  )
}

export default AccrediatedOutsideIndia
